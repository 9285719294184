import { API } from '@/shared/api';
import { DashTotalTipoCitas } from '@/shared/dtos/DashboardsDtos/DashTotalTipoCitas';
import { Comparacion_hm } from '@/shared/dtos/DashboardsDtos/Entrevista/Comparacion_hm';
import { ssmHttpService } from '@/shared/services/http-service';
import { store } from '@/store/store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
    namespaced: true,
    name: 'DashEntrevistaModule',
    store,
    dynamic: true
})
class DashEntrevistaModule extends VuexModule {
    public EvolucionEntrevista: DashTotalTipoCitas = new DashTotalTipoCitas();
    public EvolucionPacientes: Comparacion_hm = new Comparacion_hm();
    @Action({ commit: 'onGetEvolucionEntrevista' })
    public async getEvolucionEntrevista() {
        return await ssmHttpService.get(API.EvolucionEntrevistas);
    }

    @Mutation
    public onGetEvolucionEntrevista(res: DashTotalTipoCitas) {
        this.EvolucionEntrevista = res;
    }

    @Action({ commit: 'onGetHombreMujer' })
    public async getHombreMujer(objetivos: string[] = []) {
        return await ssmHttpService.post(API.EvolucionPacientes, objetivos, false);
    }

    @Mutation
    public onGetHombreMujer(res: Comparacion_hm) {
        this.EvolucionPacientes = res;
    }

}
export default getModule(DashEntrevistaModule);