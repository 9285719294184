
























import { Component, Vue } from "vue-property-decorator";
import DashEntrevistaModule from "@/store/modules/Dashboards/Empresarial/DashEntrevista-module";
@Component({
  components: {
    ChartComparacionEntreMeses: () =>
      import("@/components/Charts/ComparacionEntreMeses.vue"),
    LineMouths: () => import("@/components/Charts/LineMouths.vue"),
    DashComparacionMediciones: () =>
      import("./Entrevista/DashComparacionMediciones.vue")
  }
})
export default class DashAgenda extends Vue {
  public created() {
    DashEntrevistaModule.getEvolucionEntrevista();
  }
  public get source() {
    return DashEntrevistaModule.EvolucionEntrevista;
  }
  public get title() {
    try {
      return (
        "Comparación de consultas realidas en " +
        this.source.sum_mes_pasado.name +
        " y " +
        this.source.sum_este_mes.name
      );
    } catch (error) {}
    return "";
  }
}
